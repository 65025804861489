<template>
  <b-dropdown
    v-click-outside="hideDrop"
    :text="text"
    block
    variant="none"
    :class="customDropdownClasses"
    menu-class="w-100 custom-select-menu"
    toggle-class="p-0"
    toggle-tag="div"
    dropdown
    no-caret
    @show.prevent
    @hide.prevent
  >
    <template #button-content>
      <div
        class="select-wrapper"
        @click="handleClick"
      >
        <search-icon
          v-if="displayPrefixIcon && !!searchPlaceholder"
          class="search-icon"
        />
        <arrow-down-icon
          v-if="showArrowIcon && !!searchPlaceholder"
          class="arrow-icon"
        />
        <v-select
          :value="value"
          :options="allOptions"
          :input-id="id"
          multiple
          :deselect-from-dropdown="true"
          :class="selectClasses"
          :no-drop="true"
          :searchable="isSearchable"
          :placeholder="searchPlaceholder"
          class="dropdown"
          :map-keydown="handleKeyDown"
          @input="handleSelectInput"
          @search="onSearch"
          @option:deselected="handleOptionDeselect"
        />
      </div>
    </template>
    <template v-for="(optionGroup, index) in optionGroups">
      <div
        :id="`${id}-dropdown-el`"
        :key="index"
      >
        <b-dropdown-header
          v-if="optionGroup.title && optionGroup.options && optionGroup.options.length"
          class="country-header"
        >
          {{ optionGroup.title }}
        </b-dropdown-header>
        <b-dropdown-item
          v-for="option in optionGroup.options"
          :key="`kc_${option}`"
          :active="value.includes(option)"
          @click="onSelect(option)"
        >
          <div class="d-flex w-100 justify-content-between align-items-center">
            <span class="w-100 text-wrap">{{ option }}</span>
            <tick-icon v-if="value.includes(option)" />
          </div>
        </b-dropdown-item>
      </div>
    </template>
    <template v-if="!allOptions.length">
      <b-dropdown-item
        :disabled="true"
      >
        <div class="d-flex w-100 justify-content-between align-items-center">
          {{ noDataText }}
        </div>
      </b-dropdown-item>
    </template>
  </b-dropdown>
</template>
<script>
import {
BDropdown,
BDropdownHeader,
BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import TickIcon from '@/@core/assets/svg-components/TickIcon.vue'
import SearchIcon from '@/@core/assets/svg-components/SearchIcon.vue'
import ArrowDownIcon from '@/@core/assets/svg-components/ArrowIcon.vue'

export default {
    name: 'CustomSelect',
    components: {
      BDropdown,
      BDropdownItem,
      vSelect,
      TickIcon,
      SearchIcon,
      BDropdownHeader,
      ArrowDownIcon,
    },
    emits: ['input', 'search', 'select'],
    props: {
      optionGroups: {
        type: Array,
        default: () => [],
        required: true,
      },
      text: {
        type: String,
        default: '',
      },
      placeholder: {
        type: String,
        default: 'Select',
      },
      value: {
        type: Array,
        default: () => [],
        required: true,
      },
      theme: {
        type: String,
        default: 'black',
      },
      displayPrefixIcon: {
        type: Boolean,
        default: false,
      },
      multiple: {
        type: Boolean,
        default: false,
      },
      clearSearchOnSelect: {
        type: Boolean,
        default: false,
      },
      closeOnSelect: {
        type: Boolean,
        default: false,
      },
      noDataText: {
        type: String,
        default: 'No data found.',
      },
      id: {
        type: String,
        required: true,
        default: 'searchDropdown',
      },
      showArrowIcon: {
        type: Boolean,
        default: false,
      },
      isSearchable: {
        type: Boolean,
        default: true,
      },
      size: {
        type: String,
        default: 'large',
      },
      shouldScrollOnFocus: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        showDropdown: false,
        localSearchInput: '',
      }
    },
    computed: {
      searchPlaceholder() {
        return !this.showDropdown && !this.value?.length ? this.placeholder : ''
      },
      allOptions() {
        const allOptions = []
        this.optionGroups?.forEach(optionGroup => {
          allOptions.push(...optionGroup.options)
        })

        return allOptions
      },
      customDropdownClasses() {
        return {
          show: this.showDropdown,
          'custom-select-dropdown-grey': this.theme === 'grey',
          'custom-select-dropdown-black': this.theme === 'black',
          'small-size': this.size === 'small',
        }
      },
      selectClasses() {
        return {
          inputLeftPadding: this.displayPrefixIcon && !!this.searchPlaceholder,
          inputMinWidth: !this.multiple && !!this.localSearchInput.length,
        }
      },
    },
    watch: {
      showDropdown(val) {
        if (val && this.shouldScrollOnFocus) {
          setTimeout(() => {
            const dropdownEl = document.getElementById(`${this.id}-dropdown-el`)
            const list = dropdownEl.parentElement
            if (!this.isElementInViewport(list)) {
              window.scrollBy({
                top: list.clientHeight,
                behavior: 'smooth',
              })
            }
          }, 100)
        }
      },
    },
    methods: {
      isElementInViewport(el) {
      const {
        top, left, bottom, right,
      } = el.getBoundingClientRect()
      const { clientHeight, clientWidth } = document.documentElement
      return (
          top >= 0
          && left >= 0
          && bottom <= clientHeight
          && right <= clientWidth
        )
      },
      handleClick(e) {
        if (e.target.tagName === 'INPUT' || e.target.tagName === 'DIV') {
          this.showDropdown = !this.showDropdown
        }
      },
      onSelect(selectedOption) {
        let updatedOptions = [...this.value]
        if (updatedOptions.includes(selectedOption)) {
          updatedOptions = updatedOptions.filter(option => option !== selectedOption)
        } else if (this.multiple) {
          updatedOptions.push(selectedOption)
        } else {
          updatedOptions = [selectedOption]
        }
        this.$emit('select', updatedOptions)
        if (this.clearSearchOnSelect) {
          this.resetSearch()
        } else this.focusSearchInput()
      },
      onSearch(searchString) {
        this.localSearchInput = searchString
        this.$emit('search', searchString)
      },
      hideDrop() {
        this.showDropdown = false
        const el = document.getElementById(this.id)
        if (el) {
          el.blur()
        }
        const dropdownMenu = document.getElementsByClassName('custom-select-menu')
        if (dropdownMenu?.length) {
          dropdownMenu[0].scrollTop = 0
        }
      },
      handleSelectInput(val) {
        this.$emit('input', val)
      },
      resetSearch() {
        this.localSearchInput = ''
        const inputElement = document.getElementById(this.id)
        if (inputElement) {
          inputElement.blur()
          inputElement.dispatchEvent(new KeyboardEvent('keydown', {
              key: 'Escape',
              keyCode: 27,
              code: 'Escape',
              which: 27,
          }))
        }
        this.$emit('search', '')
      },
      handleOptionDeselect(option) {
        const updatedOptions = this.value.filter(o => o !== option)
        this.$emit('select', updatedOptions)
        if (updatedOptions.length > 0) {
          return
        }
        this.resetSearch()
        this.hideDrop()
      },
      focusSearchInput() {
        const inputElement = document.getElementById(this.id)
        if (inputElement) {
          inputElement?.focus()
        }
      },
      handleKeyDown(map, vm) {
        return {
          ...map,
          13: e => {
            e.preventDefault()
          },
          32: () => {
            if (vm.search) {
              vm.search += ' '
            }
          },
        }
      },
    },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include'; // Bootstrap includes
.inputLeftPadding {
  input {
    padding-left: 35px;
  }
}

.inputMinWidth {
  input  {
    min-width: 100%;
  }
}

.custom-select-dropdown-black {
  .dropdown-menu {
    max-height: 300px !important;
    z-index: 20;
    overflow-x: auto;
    .country-header {
      header {
        color: $primary !important;
        font-weight: 1000 !important;
      }
      background-color: $gray-600 !important;
    }
  }
}
.dropdown .vs__selected-options {
  max-height: 91px;
  overflow: auto;
}
.custom-select-dropdown-grey {
  .dropdown-menu {
    z-index: 20;
  }
  &.dropdown {
    border: none !important;
    height: unset !important;
    .vs__dropdown-toggle {
      border-color: #E0E0E0;
    }
    .vs__selected {
      background-color: #EBEAE8 !important;
      color: #000 !important;
      max-width: 90%;
      svg {
        color: #000 !important;
      }
    }
    .vs__actions {
      display: none;
    }

    .vs__selected-options {
      padding-left: 9px;
    }
  }
  .select-wrapper {
    position: relative;
  }
  .search-icon {
    position: absolute;
    left: 15px;
    top: 11px;
  }
  .arrow-icon {
    position: absolute;
    right: 15px;
    top: 11px;
  }
  input {
    margin-top: 3px;
    margin-bottom: 1px;
    max-width: calc(100% - 30px);
    &::placeholder {
      color: #757575
    }
  }
  input:placeholder-shown {
    text-overflow: ellipsis;
  }
  .dropdown-menu {
    max-height: 300px !important;
    overflow-x: auto;

    .country-header {
      header {
        color: $primary !important;
        font-weight: 1000 !important;
      }
      background-color: $gray-600 !important;
    }

    li .dropdown-item {
      &.active, &:focus {
        background-color: #EBEAE8;
        color: $primary
      }
    }
  }
  .btn {
    border: none;
  }
}

.small-size {
  &.dropdown {
    padding-bottom: 1px;
    .vs__dropdown-toggle {
      border-color: #E0E0E0;
      min-height: 38px;
    }
    .dropdown-item {
      font-size: 12px;
      padding: 0.65rem;
    }
    .vs__selected-options {
      padding-left: 4px;
    }

    .vs__selected {
      font-size: 12px;
    }
  }
  input {
    padding-bottom: 1px;
    margin-top: 5px;
    font-size: 12px !important;
  }
}

</style>
